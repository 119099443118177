import React, { Component } from 'react';
import { Grid, TextField, Button, FormControl } from '@mui/material';
import CategoryTreeView from './categoryTreeView';
import staticData from 'app/helpers/staticdata';
import Tags from '../../crudoperations/components/Tags.jsx';
import LoadDropDown from 'app/views/crudoperations/components/LoadDropdown';

class ProductViewTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: this.props.fields,
      Data: this.props.data,
      apiCalls: this.props.apiCalls,

      Types: [],
      brand: [],
      isCreateVisible: false // Initialize it as false
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let categories = await staticData.getAutoCompleteData('product_categories');
    let subcategories = await staticData.getAutoCompleteData('custom_attributes');
    const workCategories = await staticData.getArenaCategories();
    const type = await staticData.getEavData('modular_type');
    //console.log('categories', workCategories);
    let brand = await staticData.getArenaBrandName('');
    let custom_attributes=this.props.product?JSON.parse(this.props.product.custom_attributes):[];
    //console.log('Brand', Brand);
    this.setState({
      categories,
      subcategories,
      brand,
      workCategories,
      type,
      custom_attributes
    },function(){
      console.log('state updated', this.state);
    });
  };
  changeConnectData = async (data, type) => {
    this.loadData();
  };
  handleCustomAttribute =(e)=>{
    const{name,value}=e.target;
    let custom_attributes=this.state.custom_attributes;
    custom_attributes[name]=value;
    this.setState({
      ...this.state,custom_attributes:custom_attributes
    },function(){
      console.log('updated custom attribute',this.state);
      this.props.handleInputChange({target:{name:"custom_attributes",value:JSON.stringify(this.state.custom_attributes)}})
    })
    
  }
  handleCustomAttributeRemoval=(tag)=>{
    let custom_attributes=this.state.custom_attributes;
    delete(custom_attributes[tag]);
    this.setState({
      ...this.state,custom_attributes:custom_attributes
    },function(){
      console.log('deleted custom attribute',this.state);
      this.props.handleInputChange({target:{name:"custom_attributes",value:JSON.stringify(this.state.custom_attributes)}})
    })
  }

  render() {
    const { categories, subcategories, Fields, workCategories, type } = this.state;
    //console.log('categories', categories);
    //console.log('workCategories', workCategories);

    return (
      <Grid
        container
        spacing={3}
        style={{
          marginLeft: '20px'
        }}
      >
        <Grid item xs={12} sm={12} md={5}>
          <h4 className="m4">{this.props.name}</h4>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="name"
                  id="standard-basic"
                  defaultValue={this.props.product?.name}
                  onChange={this.props.handleInputChange}
                  //errorMessages={['this field is required']}
                  label="Name"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="sku"
                  id="standard-basic"
                  defaultValue={this.props.product?.sku}
                  onChange={this.props.handleInputChange}
                  //errorMessages={['this field is required']}
                  label="SKU"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
                <TextField
                  type="text"
                  name="type"
                  id="type"
                  defaultValue={this.props.product?.type}
                  onChange={this.props.handleInputChange}
                  // errorMessages={['this field is required']}
                  label="Material"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
          </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="length"
                  key={`length${this.state.custom_attributes?.length}`}
                  id="length"
                  defaultValue={this.state.custom_attributes?.length}
                  onBlur={(e)=>this.handleCustomAttribute(e)}
                  label="Length (MM)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="width"
                  key={`width${this.state.custom_attributes?.width}`}
                  id="width"
                  defaultValue={this.state.custom_attributes?.width}
                  onBlur={(e)=>this.handleCustomAttribute(e)}
                  //errorMessages={['this field is required']}
                  label="Width (MM)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  name="thickness"
                  key={`thickness${this.state.custom_attributes?.thickness}`}
                  id="thickness"
                  defaultValue={this.state.custom_attributes?.thickness}
                  onBlur={(e)=>this.handleCustomAttribute(e)}
                  // errorMessages={['this field is required']}
                  label="Thickness (MM)"
                  validators={['required', 'minStringLength: 4']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="medium"
                  multiline
                  rows={4}
                  type="text"
                  name="description"
                  label="Product Description"
                  variant="outlined"
                  sx={{ mt: 1 }}
                  defaultValue={this.props.product?.description}
                  onChange={this.props.handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
             {subcategories && (
              <Tags
                key={this.state.custom_attributes}
                options={subcategories}
                label={`Custom Fields    (Ex: Field : value)`}
                placeholder={`Fields to be added in `}
                baseModel={'custom_attributes'}
                defaultValue={this.state.custom_attributes}
                handleChange={(e)=>this.handleCustomAttribute(e)}
                handleRemove={(tag)=>this.handleCustomAttributeRemoval(tag)}
              />
            )}
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <div style={{ alignSelf: 'center', marginLeft: '70%', marginTop: '40px' }}>
              {!this.props.hideButton && (
                <Button
                  size="lg"
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.createProduct()}
                >
                  Add Product
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <h4 className="m4">Product Category</h4>
          <CategoryTreeView
            key={btoa(JSON.stringify(categories))}
            data={categories}
            type="product_categories"
            field="name"
            value="id"
            selected={
              this.props.product?.product_categories
                ? { data: this.props.product?.product_categories, objectName: '' }
                : { data: [], objectName: '' }
            }
            onItemClick={(data) => this.props.handleCategoryChange(data, 'product_categories')}
            connectData={(data) => this.changeConnectData(data, 'product_categories')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <h4 className="m4">Work Category</h4>
          {/* <AddIcon onClick={() => this.setState({ isCreateVisible: true })} /> */}
          <CategoryTreeView
            key={workCategories}
            data={workCategories}
            type="work_categories"
            field="sow"
            j5
            value="id"
            selected={
              this.props.product?.categories
                ? { data: this.props.product?.categories, objectName: 'category_id' }
                : { data: [], objectName: 'category_id' }
            }
            onItemClick={(data) => this.props.handleCategoryChange(data, 'categories')}
            connectData={(data) => this.changeConnectData(data, 'categories')}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ProductViewTab;
