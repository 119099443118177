import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import ProductList from './views/arena/productList';
import AddProduct from './views/arena/addProduct';
import ProductView from './views/arena/productView';
import { TreeView } from '@mui/lab';
import ListView from './views/brand/listView';
import Details from './views/crudoperations/details';
import Midnav from './views/Midnav';
import AddBulkProducts from './views/arena/AddBulkProducts';
import EmailVerify from './views/sessions/VerifyEmail';
import UserProfile from './components/user/UserProfile';
import ImageRenders from './views/ai/ImageRenders';
import ShopByRoom from './components/user/ShopByRoom';
import MyConcepts from './components/user/MyConcepts';
import ShopByProduct from './components/user/ShopByProduct';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

// echart page
const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));
const DirectSales = Loadable(lazy(() => import('app/views/orders/DirectSales')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin
      },
      {
        path: '/shopbyroom',
        element: <ShopByRoom />,
        auth: authRoles.admin
      },
      {
        path: '/shopbyproduct',
        element: <ShopByProduct />,
        auth: authRoles.admin
      },
      {
        path: '/myconcepts',
        element: <MyConcepts />,
        auth: authRoles.admin
      },
      {
        path: '/ai/renders',
        element: <ImageRenders />,
        auth: authRoles.admin
      },
      {
        path: '/user/profile',
        element: <UserProfile />,
        auth: authRoles.admin
      },
      {
        path: '/order/cart',
        element: <DirectSales />,
        auth: authRoles.admin
      },
      {
        path: '/arena/productList',
        element: <ProductList />,
        auth: authRoles.admin
      },
      {
        path: '/arena/products/add',
        element: <AddProduct />,
        auth: authRoles.admin
      },
      {
        path: '/arena/products/bulkadd',
        element: <AddBulkProducts />,
        auth: authRoles.admin
      },
      {
        path: '/arena/product/view/:id',
        element: <ProductView />,
        auth: authRoles.admin
      },
      {
        path: '/treeView',
        element: <TreeView />,
        auth: authRoles.admin
      },

      {
        path: '/crud/:type',
        element: <ListView />,
        auth: authRoles.admin
      },
      {
        path: '/navigateto/:type',
        element: <Midnav />,
        auth: authRoles.admin
      },

      {
        path: '/crud/detail/:crud/:id',
        element: <Details key={new Date()} />,
        auth: authRoles.admin
      },

      // e-chart rooute
      {
        path: '/charts/echarts',
        element: <AppEchart />,
        auth: authRoles.editor
      }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/email/verify', element: <EmailVerify /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },

  { path: '/', element: <Navigate to="/dashboard/default" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
