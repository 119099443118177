import { Box, styled, Grid } from '@mui/material';
import React, { Component } from 'react';
import { MatxLogo } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { Span } from '../Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import config from '../../constants/index.jsx';
import Authorization from 'app/helpers/authorization';
import ForgotPassword from 'app/views/sessions/ForgotPassword';
import PersonalDetails from 'app/views/sessions/PersonalDetails';

class ShopByRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'Personal Details' // Set the initial tab value
    };
  }
  setPiqqeToken = () => {
    const setedPiqqeToken = Authorization.piqqeTokenizer();
    console.log('piqqeToken', setedPiqqeToken);
    if (setedPiqqeToken) {
      localStorage.setItem('piqqeToken', setedPiqqeToken);
      console.log('Token has been set in localStorage');
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    console.log(this.setPiqqeToken());
    return (
      <Grid md={12}>
        <Grid item md={12}>
          <iframe
            style={{ width: '100%', height: '1000px', border: 0 }}
            src={config.piqqe_url + '?token=' + Authorization.piqqeTokenizer()}
            title="description"
          />
        </Grid>
      </Grid>
    );
  }
}

export default ShopByRoom;
