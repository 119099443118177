import React, { Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Card, Checkbox, Grid, Typography, createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileView from '../../bids/components/fileView';
import CategoryTreeView from './categoryTreeView.jsx';
import Displayer from '../../../helpers/displayer.jsx';

// Create a custom theme with desired typography styles
const theme = createTheme({
  typography: {
    fontWeightBold: 900,
    body1: {
      color: 'blue'
    }
  }
});

const ListRow = ({ product, canView, callback }) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment key={product.id}>
        <Grid
          container
          spacing={4}
          className="mt-4"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '30px'
          }}
        >
          {product.base_image ? (
            <Grid item md={2}>
              <FileView id={product.id} url={Displayer.absolutingImageURl(product.base_image)} />
            </Grid>
          ) : null}
          <Grid item md={6}>
            <Grid container>
              <Grid item md={4}>
                <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                  Type:
                </Typography>
                <Typography variant="body1">{product.type}</Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                  Sku:
                </Typography>
                <Typography variant="body1">{product.sku}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                  Name:
                </Typography>
                <Typography variant="body1" color="blue">
                  {product.name}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                  Price:
                </Typography>
                <Typography variant="body1">{product.price}</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
                  Description:
                </Typography>
                <Typography variant="body1">{product.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Typography variant="subtitle1" fontWeight="bold" className="text-muted">
              Category:
            </Typography>
            <Typography variant="body1">{product.category}</Typography>
          </Grid>
          <Grid item md={0}>
            {canView ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => callback(product.id)}
              >
                <VisibilityIcon />
              </Button>
            ) : (
              <Checkbox variant="contained" color="primary" onChange={() => callback(product.id)} />
            )}
          </Grid>
        </Grid>
        <hr />
      </Fragment>
    </ThemeProvider>
  );
};

export default ListRow;
