import { Box, Button, Card, Grid, styled, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../constants/index.jsx';
import Postie from 'app/helpers/postie';
import Authorization from 'app/helpers/authorization.jsx';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center'
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: 10,
  background: theme.palette.background.default
}));

const ForgotPasswordRoot = styled(JustifyBox)(() => ({
  minHeight: '50vh !important',
  '& .card': {
    maxWidth: 500,
    margin: '1rem',
    borderRadius: 12
  }
}));

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({});
  console.log('passwordData', passwordData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('handleChange', name, value);
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = async () => {
    const postData = {
      authToken: localStorage.getItem(config.Token_name),
      oldPassword: passwordData.oldPassword,
      newPassword: passwordData.newPassword,
      cnfmPassword: passwordData.confirmPassword
    };
    const url = config.BASE_API_URL + 'user/changepwd';
    const json = await Postie.sendAsynchrnousCall(postData, url);
    console.log('json', json);
    if (json.status === 'success') {
      Postie.showAlert(json);
    }
  };

  return (
    <ForgotPasswordRoot>
      <Card className="card">
        <Grid container>
          <Grid item xs={12}>
            <ContentBox>
              <form onSubmit={handleFormSubmit}>
                <TextField
                  type="password"
                  name="oldPassword"
                  size="small"
                  label="Old Password"
                  variant="outlined"
                  onBlur={handleChange}
                  sx={{ mb: 3, width: '100%' }}
                />
                <TextField
                  type="password"
                  name="newPassword"
                  size="small"
                  label="New Password"
                  variant="outlined"
                  onBlur={handleChange}
                  sx={{ mb: 3, width: '100%' }}
                />
                <TextField
                  type="password"
                  name="confirmPassword"
                  size="small"
                  label="Confirm Password"
                  variant="outlined"
                  onChange={handleChange}
                  sx={{ mb: 3, width: '100%' }}
                />

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => handleFormSubmit()}
                >
                  Reset Password
                </Button>

                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onBlur={() => navigate(-1)}
                  sx={{ mt: 2 }}
                >
                  Go Back
                </Button>
              </form>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </ForgotPasswordRoot>
  );
};

export default ForgotPassword;
