import { Box, Button, Card, Grid, styled, TextField } from '@mui/material';
import Postie from 'app/helpers/postie';
import { useState } from 'react';
import config from '../../constants/index.jsx';
import { useNavigate } from 'react-router-dom';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center'
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: 32,
  background: theme.palette.background.default
}));

const ForgotPasswordRoot = styled(JustifyBox)(() => ({
  background: '#1A2038',
  minHeight: '100vh !important',
  '& .card': {
    maxWidth: 800,
    margin: '1rem',
    borderRadius: 12
  }
}));

const EmailVerify = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');

  const handleFormSubmit = async () => {
    const postData = {
      type: 'email_verification',
      token: otp
    };
    const url = config.BASE_API_URL + 'cms/auth/verify';
    let json = await Postie.sendAsynchrnousCall(postData, url);
    console.log('json', json);
    if (json.status === 'success') {
      Postie.navigateLink('/');
    } else {
      Postie.showAlert(json);
    }
  };

  return (
    <ForgotPasswordRoot>
      <Card className="card">
        <Grid container>
          <Grid item xs={12}>
            <JustifyBox p={4}>
              <img width="200" src="/assets/images/illustrations/dreamer.svg" alt="" />
            </JustifyBox>
            <Grid style={{ marginLeft: '20px', padding: '5px' }}>
              <h4>Please Enter OTP Sended to your Register Email</h4>
            </Grid>
            <ContentBox>
              <form onSubmit={handleFormSubmit}>
                <TextField
                  type="otp"
                  name="otp"
                  size="small"
                  label="Enter OTP"
                  value={otp}
                  variant="outlined"
                  onChange={(e) => setOtp(e.target.value)}
                  sx={{ mb: 3, width: '100%' }}
                />

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => handleFormSubmit()}
                >
                  Verify
                </Button>

                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={() => navigate('/')}
                  sx={{ mt: 2 }}
                >
                  Do Later
                </Button>
              </form>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </ForgotPasswordRoot>
  );
};

export default EmailVerify;
