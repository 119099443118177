import React, { Component } from 'react';
import { Grid } from '@mui/material';
import Rud from '../crudoperations/rud';
import displayer from 'app/helpers/displayer';
import config from 'app/constants/index';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: '',
      fields: [],
      data: [],
      apiCalls: [],
      children: [],
      addDiffrent: false,
      pageNo: 1,
      filters: {}
    };
  }

  componentDidMount = () => {
    const queryParams = displayer.getUrlParams('/crud/');
    console.log(queryParams);
    this.loadData(queryParams[0]);
  };

  shouldComponentUpdate() {
    return true;
  }

  loadData = async (type) => {
    let userLogged = await Auth.isUserLoggedIn();
    if (userLogged) {
      let url =
        config.BASE_API_URL + 'crud/' + type + '/' + this.state.pageNo + '/' + Auth.urlTokenizer();
      let postData = { filters: this.state.filters };
      let json = await Postie.sendAsynchrnousCall(postData, url);

      if (json.status === 'success') {
        this.setState(
          {
            ...this.state,
            heading: type.toUpperCase(),
            fields: json.data.fields,
            // data:
            //   typeof this.state.filters === 'object' && Object.keys(this.state.filters).length > 0
            //     ? json.data.data
            //     : [],
            data: json.data.data,
            apiCalls: json.data.apiCalls,
            children: json.data.children,
            addDiffrent: json.data.addDiffrent
          },
          function () {
            console.log(
              'loadData',
              this.state,
              this.state.filters,
              typeof this.state.filters === 'object',
              typeof this.state.filters === 'object' && Object.keys(this.state.filters).length > 0
            );
          }
        );
      }
    } else {
      console.log('userLogged', userLogged);
      window.location.href = '/session/signin';
    }
  };
  filterPage(pageNo) {
    console.log('Filterpage', pageNo);
    this.setState({ ...this.state, pageNo: pageNo }, function () {
      this.loadData(this.state.heading.toLowerCase());
    });
  }
  filterData(event) {
    this.setState(
      {
        ...this.state,
        filters: { ...this.state.filters, [event.target.name]: event.target.value }
      },
      function () {
        this.loadData(this.state.heading.toLowerCase());
      }
    );
  }
  render() {
    return (
      <Grid item md="12">
        {this.state.fields.length > 0 ? (
          <Rud
            key={this.state.data}
            heading={this.state.heading}
            Fields={this.state.fields}
            data={this.state.data}
            apiCalls={this.state.apiCalls}
            addDifferent={this.state.addDiffrent}
            changePage={(desiredPage, crud) => this.filterPage(desiredPage)}
            filterData={(e) => this.filterData(e)}
            filterValues={this.state.filters}
            defaultValue={{}}
            callback={() => this.loadData(this.state.heading.toLowerCase())}
          />
        ) : (
          <h5>Loading...</h5>
        )}
      </Grid>
    );
  }
}

export default ListView;
