import React, { Component } from 'react';
import { Grid, TextField, Button, FormControl } from '@mui/material';
import UploadFile from '../files/uploadFile';
import FileView from '../bids/components/fileView';
import displayer from 'app/helpers/displayer';
import AICaptioner from '../crudoperations/components/AICaptioner';
import AIReplacer from '../crudoperations/components/AIReplacer';
import { Fragment } from 'react';
import ConnectModule from '../crudoperations/connectModule';
import Authorization from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import config from '../../constants/index';
import ConnectGallery from '../crudoperations/connectGallery';

class ImageRenders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelImage: '',
      referenceImage: '',
      modelCaption: '',
      referenceCaption: '',
      connectModule: false
    };
  }
  handleStateUpdate(elem) {
    console.log('handleStateUpdate', elem.target.name, elem.target.value);
    this.setState({ ...this.state, [elem.target.name]: elem.target.value });
  }
  loadProduct(product) {
    console.log('product', product);
    this.setState({
      ...this.state,
      referenceImage: product.base_image,
      referenceCaption: product.prompt,
      product: product
    });
  }
  loadImage(data) {
    this.setState({
      ...this.state,
      modelImage: data.image_name
    });
  }
  setConnectModule() {
    this.setState({ ...this.state, connectModule: true });
  }
  async updateProduct(caption) {
    let serviceUrl = config.BASE_API_URL + 'crud/update/products';
    let postData = {
      field: 'prompt',
      value: caption,
      id: this.state.product.id,
      authToken: localStorage.getItem(config.Token_name)
    };
    let reply = await Postie.sendAsynchrnousCall(postData, serviceUrl);
    if (reply.status === 'success') {
      this.handleStateUpdate({
        target: { name: 'referenceCaption', value: caption }
      });
    } else {
      Postie.showAlert(reply);
    }
  }
  render() {
    const { modelImage, referenceImage, modelCaption, referenceCaption } = this.state;

    return (
      <Fragment>
        <Grid container spacing={2} sx={{ margin: '10px' }}>
          <Grid item xs={12} sm={12} md={6}>
            <h3>Model Image</h3>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <UploadFile
                  filename="Image"
                  displayTosection={false}
                  areaName=""
                  type="model_image"
                  projectId="0"
                  areaId="0"
                  productIndex={0} // Pass the product index to the component
                  callback={(File) =>
                    this.handleStateUpdate({ target: { name: 'modelImage', value: File.data.url } })
                  } // Pass the index to the callback
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ConnectGallery
                  key={`connect-gallery`}
                  baseModule={'gallery'}
                  ConnectModule={'gallery'}
                  baseModuleId={1089}
                  onlyCallBack={true}
                  apiCall={{ loadDataUrl: 'connect/gallery/gallery/' }}
                  callback={(data) => this.loadImage(data)}
                  fields={[
                    {
                      label: 'Image',
                      dbname: 'image_name',
                      ftype: [
                        {
                          type: 'file',
                          placeholder: 'Image',
                          dbname: 'image_name',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'Tags',
                      dbname: 'tags',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Tags',
                          dbname: 'tags',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },

                    {
                      label: 'Actions',
                      dbname: 'status',
                      ftype: [
                        {
                          type: 'Button',
                          placeholder: 'Delete',
                          action: 'delete',
                          color: 'secondary'
                        }
                      ]
                    }
                  ]}
                  data={{
                    currentPage: 1,
                    display_note: 'Showing 0 of 0',
                    records: [],
                    totalpages: 0
                  }}
                  baseModuleData={''}
                  defaultValue={''}
                  addDifferent={''}
                />
              </Grid>
            </Grid>
            <div
              style={{
                margin: '5px',
                border: '1px solid #dddddd',
                borderRounded: '5px',
                width: '300px'
              }}
            >
              {modelImage && <FileView url={displayer.absolutingImageURl(modelImage)} />}
            </div>
            {modelCaption && <div style={{ width: '90%' }}>{modelCaption}</div>}
            {modelImage && (
              <AICaptioner
                placeholder={'Model Caption'}
                prompt={'describe the image'}
                image={modelImage}
                defaultValue={''}
                callback={(caption) =>
                  this.handleStateUpdate({ target: { name: 'modelCaption', value: caption } })
                }
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h3>Product Image</h3>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <UploadFile
                  filename="Image"
                  displayTosection={false}
                  areaName=""
                  type="model_image"
                  projectId="0"
                  areaId="0"
                  productIndex={1} // Pass the product index to the component
                  callback={(File) =>
                    this.handleStateUpdate({
                      target: { name: 'referenceImage', value: File.data.url }
                    })
                  } // Pass the index to the callback
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ConnectModule
                  key={`connect-PRODUCTS`}
                  baseModule={'PRODUCTS'}
                  ConnectModule={'products'}
                  baseModuleId={1089}
                  onlyCallBack={true}
                  apiCall={{ loadDataUrl: 'connect/cfg/products/' }}
                  callback={(data) => this.loadProduct(data)}
                  fields={[
                    {
                      label: 'Image',
                      dbname: 'base_image',
                      ftype: [
                        {
                          type: 'file',
                          placeholder: 'Image',
                          dbname: 'base_image',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'Work Category',
                      dbname: 'WorkCategory',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Work Category',
                          dbname: 'WorkCategory',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'Product Category ',
                      dbname: 'ProductCategory',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Product Category ',
                          dbname: 'ProductCategory',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'Material',
                      dbname: 'type',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Material',
                          dbname: 'type',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'name',
                      dbname: 'name',
                      ftype: [
                        {
                          type: 'textArea',
                          placeholder: 'Name',
                          dbname: 'id',
                          action: 'update',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'Avg Cost',
                      dbname: 'rate',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Avg Cost',
                          dbname: 'rate',
                          action: '',
                          color: 'primary',
                          disabled: true
                        }
                      ]
                    },
                    {
                      label: 'Width(mm)',
                      dbname: 'width',
                      ftype: [
                        {
                          type: 'number',
                          placeholder: 'Width(mm)',
                          dbname: 'width',
                          action: 'update',
                          color: 'primary'
                        }
                      ]
                    },
                    {
                      label: 'Height(mm)',
                      dbname: 'height',
                      ftype: [
                        {
                          type: 'number',
                          placeholder: 'Height(mm)',
                          dbname: 'height',
                          action: 'update',
                          color: 'primary'
                        }
                      ]
                    },
                    {
                      label: 'Qty',
                      dbname: 'qty',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Qty',
                          action: 'update',
                          color: 'primary'
                        }
                      ]
                    },
                    {
                      label: 'Cost',
                      dbname: 'cost',
                      ftype: [
                        {
                          type: 'text',
                          placeholder: 'Cost',
                          action: 'update',
                          color: 'primary'
                        }
                      ]
                    },
                    {
                      label: 'Visibility',
                      dbname: 'Visibility',
                      ftype: [
                        {
                          type: 'checkbox',
                          placeholder: 'Visibility',
                          action: 'update',
                          color: 'primary',
                          options: ['Visible']
                        }
                      ]
                    },
                    {
                      label: 'Actions',
                      dbname: 'status',
                      ftype: [
                        {
                          type: 'Button',
                          placeholder: 'Delete',
                          action: 'delete',
                          color: 'secondary'
                        }
                      ]
                    }
                  ]}
                  data={{
                    currentPage: 1,
                    display_note: 'Showing 0 of 0',
                    records: [],
                    totalpages: 0
                  }}
                  baseModuleData={''}
                  defaultValue={''}
                  addDifferent={''}
                />
              </Grid>
            </Grid>
            <div
              style={{
                margin: '5px',
                border: '1px solid #dddddd',
                borderRounded: '5px',
                width: '300px'
              }}
            >
              {referenceImage && (
                <FileView
                  url={displayer.absolutingImageURl(referenceImage)}
                  style={{ maxHeight: '300px' }}
                />
              )}
            </div>
            {referenceImage && (
              <AICaptioner
                placeholder={'Generate Prompt'}
                prompt={'Describe the image'}
                image={referenceImage}
                defaultValue={''}
                callback={(caption) => this.updateProduct(caption)}
              />
            )}
            {referenceCaption && <div style={{ width: '90%' }}>{referenceCaption}</div>}
          </Grid>
          {referenceCaption && modelImage && referenceImage && (
            <Grid item xs={12} sm={12} md={12}>
              <AIReplacer
                image={modelImage}
                replaceImage={referenceImage}
                searchPrompt={''}
                prompt={referenceCaption}
                updateModel={''}
                updateField={''}
                updateId={''}
                callback={() => console.log('image generated')}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default ImageRenders;
