import { Box, styled, Grid } from '@mui/material';
import React, { Component } from 'react';
import { MatxLogo } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { Span } from '../Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import config from '../../constants/index.jsx';
import Authorization from 'app/helpers/authorization';
import ForgotPassword from 'app/views/sessions/ForgotPassword';
import PersonalDetails from 'app/views/sessions/PersonalDetails';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'Login Details' // Set the initial tab value
    };
  }
  setPiqqeToken = () => {
    const setedPiqqeToken = Authorization.piqqeTokenizer();
    console.log('piqqeToken', setedPiqqeToken);
    if (setedPiqqeToken) {
      localStorage.setItem('piqqeToken', setedPiqqeToken);
      console.log('Token has been set in localStorage');
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    console.log(this.setPiqqeToken());
    return (
      <Grid md={12}>
        <TabContext value={this.state.tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={this.handleChange} aria-label="user profile tabs">
              <Tab label="Login Details" value="Login Details" />
              <Tab label="Manage Password" value="Manage Password" />
              <Tab label="Organisation" value="Organisation" />
              <Tab label="Manage Staff" value="Manage Staff" />
            </TabList>
          </Box>
          <TabPanel value="Login Details">
            <Grid item md={12}>
              <PersonalDetails />
            </Grid>
          </TabPanel>
          <TabPanel value="Manage Password">
            <Grid item md={12}>
              <ForgotPassword />
            </Grid>
          </TabPanel>
          <TabPanel value="Organisation">
            <Grid item md={12}>
              <iframe
                style={{ width: '100%', height: '550px', border: 0 }}
                src={
                  config.piqqe_url +
                  'organisation' +
                  config.piqqe_extension +
                  '?token=' +
                  Authorization.piqqeTokenizer()
                }
                title="description"
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Manage Staff">
            <Grid item md={12}>
              <iframe
                style={{ width: '100%', height: '550px', border: 0 }}
                src={config.piqqe_url + 'staff' + config.piqqe_extension}
                title="description"
              />
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    );
  }
}

export default UserProfile;
