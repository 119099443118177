import config from '../constants/index';
import Postie from './postie';
import { Buffer } from 'buffer';

const isUserLoggedIn = async () => {
  if (localStorage.getItem(config.Token_name) !== null) {
    let serviceUrl = config.BASE_API_URL + 'user/validate/' + urlTokenizer();
    let json = await Postie.fetchUrl(serviceUrl);
    if (json) {
      return true;
    }
  }
  return false;
};

const makeLogin = async (phone, password) => {
  var postData = { username: phone, password: password };
  var servicesUrl = config.BASE_API_URL + 'user/login';
  var json = await Postie.sendAsynchrnousCall(postData, servicesUrl);
  if (json.status === 'success') {
    localStorage.setItem(config.Token_name, json.data.member_token);
  }
  return json;
};

const getLoggedInUserData = (field = 'member_name') => {
  let resp = false;
  if (localStorage.getItem(config.Token_name) !== null) {
    let json = JSON.parse(atob(localStorage.getItem(config.Token_name)));
    resp = json[field];
  }
  return resp;
};
const getLoggedInUserOrganizationData = (field = 'name') => {
  let resp = false;
  if (localStorage.getItem(config.Token_name) !== null) {
    let json = JSON.parse(atob(localStorage.getItem(config.Token_name)));
    resp = json['organization'][field];
  }
  return resp;
};

const getLoggedInUserRole = () => {
  let resp = false;
  if (localStorage.getItem(config.Token_name) !== null) {
    let json = JSON.parse(atob(localStorage.getItem(config.Token_name)));
    resp = json.role.role_name.toLowerCase();
  }
  return resp;
};

const checkUserValidColabrator = (colabrators) => {
  let resp = false;
  let memberId = getLoggedInUserData('id');
  colabrators.forEach((record) => {
    if (record.team_id === memberId) {
      resp = true;
    }
  });
  return resp;
};

const getUserProjectRole = (colabrators) => {
  let requestor = {};

  colabrators &&
    colabrators.forEach((colabrator) => {
      if (colabrator.team_id === getLoggedInUserData('id')) {
        requestor = colabrator;
        if (requestor.is_admin) {
          requestor.role = 'admin';
        } else if (requestor.is_lead) {
          requestor.role = 'lead';
        } else {
          requestor.role = requestor.role.toLowerCase();
        }
      }
    });

  return requestor;
};

const logout = () => {
  localStorage.removeItem(config.Token_name);
};

const urlTokenizer = () => {
  let udetail = getLoggedInUserData('id') + '-' + getLoggedInUserData('member_token_created_on');
  const encodedString = Buffer.from(udetail).toString('base64');
  return encodedString;
};
const piqqeTokenizer = () => {
  console.log('cms user id', getLoggedInUserData('cms_user_id'));
  let udetail =
    getLoggedInUserData('cms_user_id') +
    '-' +
    getLoggedInUserData('member_token_created_on') +
    '-' +
    getLoggedInUserData('member_name');
  const encodedString = Buffer.from(udetail).toString('base64');
  return encodedString;
};

const Authorization = {
  isUserLoggedIn,
  logout,
  getLoggedInUserData,
  getLoggedInUserRole,
  urlTokenizer,
  checkUserValidColabrator,
  getUserProjectRole,
  getLoggedInUserOrganizationData,
  makeLogin,
  piqqeTokenizer
};

export default Authorization;
